<template>
  <div>
        <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                    <li class=" a"><router-link to="/index5/sy55">平台管理员</router-link> </li>
                     <li class="active a">系统公告</li>
                </div>
                 <div  class="item">  
                    <li class="a"><router-link to="/index3/xiaoxi">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i>消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible11 = true">技术专员<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                    <el-dialog title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible11">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                       
                        </div>
                        </el-dialog>  
             </ul>
        </div>
        <div class="search" style="padding:7.5px 40px; display:flex;font-size:14px;justify-content: space-between;align-items: center;">
            <div style=" display:flex;">
                 <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">关键字</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input1">
                </el-input>
            </div>
             <div class="block" style="margin-right:15px">
                <span class="demonstration" style="margin-right:5px">发布时间</span>
                <el-date-picker
                style="width:150px;"
                v-model="value1"
                type="date"
                placeholder="选择日期">
                </el-date-picker>
            </div>
            <div>
                <span class="demonstration" style="margin-right:5px;">公告类型</span>
                    <el-select v-model="select" slot="prepend" placeholder="请选择" style="width:150px">
                    <el-option label="普通公告" value="1"></el-option>
                    <el-option label="重要公告" value="2"></el-option>
                  
                 </el-select>
             </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 35px;line-height:35px;cursor: pointer;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck1">重置</p> <p style="width: 80px;
height: 35px;line-height:35px;text-align:center;cursor: pointer;
border: 1px solid #DFE0E8;" @click="ck2">查询</p>
            </div>
        </div>
         <div class="content" >
           
<el-row style="padding:10px 40px;  background:#fff">
   
    <div style="    display: flex;
    justify-content: space-between;">
       <p style="font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 22px;">公告列表</p>
        <p class="kk" style="font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #0034FF;background: rgba(0, 52, 255, 0.1);
padding:5px 15px;cursor: pointer;
border: 1px solid rgba(0, 52, 255, 0.1);" @click="dialogFormVisible = true"> + 创建公告</p>



<el-dialog title="创建公告" :visible.sync="dialogFormVisible"  >
  <el-form :model="form">
  
    <el-form-item label="接受角色" >
      <el-select v-model="form.a1" placeholder="请选择公告角色">
        <el-option label="技术总监" value="1"></el-option>
        <el-option label="市场总监" value="2"></el-option>
              <el-option label="财务总监" value="3"></el-option>
        <el-option label="代理商" value="4"></el-option>
          <el-option label="技术专员" value="5"></el-option>
        <el-option label="调律师" value="6"></el-option>
              <el-option label="代理商业务经理" value="7"></el-option>
        <el-option label="琴行" value="8"></el-option>
             <el-option label="普通管理员" value="9"></el-option>
        <el-option label="管理员" value="10"></el-option>      <el-option label="市场业务经理" value="11"></el-option><el-option label="全部" value="0"></el-option>
      </el-select>


        
    </el-form-item>
    <el-form-item label="公告内容"  >
         <el-select v-model="form.a2" placeholder="请选择公告类型">
        <el-option label="普通公告" value="0"></el-option>
         <el-option label="重要公告" value="1"></el-option>          </el-select>
    </el-form-item>

    <el-form-item label="发布时间"  >
       
               <div class="block">
                   
                    <el-date-picker
                    v-model="value1"
                    type="datetime"
                    placeholder="选择日期时间">
                    </el-date-picker>
                </div>
      
    </el-form-item>
    <el-form-item label="公告主题">
      <el-input v-model="form.a3" style="width:220px" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="公告内容" >
      <el-input   type="textarea" :rows="6" v-model="form.a4"  style="width:220px" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
   
    <el-button type="primary" @click="sure">确 定</el-button>
  </div>
</el-dialog>
    </div>

  <el-col :span="24"  style="margin-top:30px">  <el-table
      :border="true"
    :data="tableData"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%">
    
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="subject"
      label="公告编号"
      width="100">
    </el-table-column>
    <el-table-column
      label="公告类型"
      prop="type" 
      width="100">
    
    </el-table-column>

       <el-table-column
      label="用户ID"
    prop="name" 
      
      width="100">
    
    </el-table-column>
      

    
      <el-table-column
        prop="content" 
      label="内容" 
           :show-overflow-tooltip="true" 
      width="300">
   
    </el-table-column>

  <el-table-column
        prop="name" 
      label="发布人"
      width="110">
   
    </el-table-column>
        <el-table-column
        prop="create_time" 
      label="创建时间"
           :show-overflow-tooltip="true" 
      width="150">
   
    </el-table-column>

    
        <el-table-column
        prop="effect_time" 
      label="发布时间"
      :show-overflow-tooltip="true" 
      width="150">
   
    </el-table-column>



   <el-table-column
     
      label="操作"
   >
     <template slot-scope="scope">
        <el-button
          size="mini"
         
           @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
             <el-button
          size="mini"
          type="danger"
           @click="handleEdit1(scope.$index, scope.row)">删除</el-button>
     </template>
    </el-table-column>
  </el-table>
</el-col>
</el-row>


<el-dialog title="创建公告" :visible.sync="dialogFormVisible1"  >
  <el-form :model="form">
  
    <el-form-item label="接受角色" >
      <el-select v-model="id" placeholder="请选择公告角色">
        <el-option label="技术总监" value="1"></el-option>
        <el-option label="市场总监" value="2"></el-option>
              <el-option label="财务总监" value="3"></el-option>
        <el-option label="代理商" value="4"></el-option>
          <el-option label="技术专员" value="5"></el-option>
        <el-option label="调律师" value="6"></el-option>
              <el-option label="代理商业务经理" value="7"></el-option>
        <el-option label="琴行" value="8"></el-option>
             <el-option label="普通管理员" value="9"></el-option>
        <el-option label="管理员" value="10"></el-option>    
          <el-option label="市场业务经理" value="11"></el-option>
          <el-option label="全部" value="0"></el-option>
      </el-select>


        
    </el-form-item>
    <el-form-item label="公告内容"  >
         <el-select v-model="type" placeholder="请选择公告类型">
        <el-option label="普通公告" value="0"></el-option>
         <el-option label="重要公告" value="1"></el-option>          </el-select>
    </el-form-item>

    <el-form-item label="发布时间"  >
       
               <div class="block">
                   
                    <el-date-picker
                    v-model="value1"
                    type="datetime"
                    placeholder="选择日期时间">
                    </el-date-picker>
                </div>
      
    </el-form-item>
    <el-form-item label="公告主题">
      <el-input v-model="subject" style="width:220px" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="公告内容" >
      <el-input   type="textarea" :rows="6" v-model="content"  style="width:220px" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
   
    <el-button type="primary" @click="sure1">确 定</el-button>
  </div>
</el-dialog>
   
        
         </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            formLabelWidth: '120px',
        content:'',
                effect_time :'',
               id :'',
              subject:'',
             type:'',
                   password:'',
                   password2:false,
                  password3:'',
info:[],
        dialogFormVisible: false,
        dialogFormVisible1:false,
             dialogFormVisible11:false,  
        form: {
         a1:'',
         a2:'',
         a3:'',
         a4:'',
        value1: '',
        },
            tableData:'',
            select:'',
            input1:'',
   
                    value1: '',
                
      }
        
    },
    mounted(){
       this.$axios.get("/s/backend/api/index/top_six_customer_statistic", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         
            this.tableData = res.data.result
        })
        .catch(function(){
           
        })
  

       this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           console.log(this.info)
        })
        .catch(function(){
           
        })
         this.$axios.get("/s/backend/api/message/message_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
            'page':'1',
            'limit':'100',

}
})
  .then(res=>{
         
           this.tableData= res.data.data
        })
        .catch(function(){
           
        })
    },
    methods:{
           tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
          
        },
          a11(){
          this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:this.info,
                        }) .then(res=>{
                            this.dialogFormVisible = false
                              this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
                        })
        .catch(function(){
           
        })  
        },
           a22(){
            this.password2=true
         
              
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
        sure(){
            console.log()

            var d = new Date(this.value1);



            var a22 =  d.getMonth() + 1
            a22 = a22 < 10 ? ('0' + a22) : a22
         var a33 =   d.getDate() 
            a33 = a33 < 10 ? ('0' + a33) : a33

                     var a44 =   d.getHours() 
            a44 = a44 < 10 ? ('0' + a44) : a44
                   var a55 =   d.getMinutes()
            a55 = a55 < 10 ? ('0' + a55) : a55
                   var a66=  d.getSeconds()
            a66 = a66 < 10 ? ('0' + a66) : a66

           var a  =d.getFullYear() + '-' + a22+ '-' + a33 + ' ' + a44 + ':' + a55 + ':' + a66
         

            if(this.form.a1!== '' && this.form.a2!=='' && this.form.a3 !== '' &&this.form.a4!=='' &&this.value1!==''){
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/message/create_message",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                            "content": this.form.a4,
                            "effect_time": a,
                            "roleid": this.form.a1,
                            "subject": this.form.a3,
                            "type": this.form.a2
                }
                        }) .then(res=>{
                            this.dialogFormVisible = false
                            this.$axios.get("/s/backend/api/message/message_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
            'page':'1',
            'limit':'100',

}
})
  .then(res=>{
         
           this.tableData= res.data.data
        })
        .catch(function(){
           
        })
                        })
            }else{
                this.$message({
                   message:'请填写完整信息',
                  type: 'waring'
                     });
            }
                
        },



        sure1(){
                if(this.content!== '' && this.effect_time!=='' && this.id!== '' &&this.subject!=='' &&this.type!==''){
             
            var types  =parseInt(this.type)
            if(this.type == '重要'){
                var types  =parseInt(1) 
            }else{
                  var types  =parseInt(0) 
                }
            console.log(this.type)
                var ids  =parseInt(this.id)

                 this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/message/message_update",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                            "content": this.content,
                            "effect_time": this.effect_time,
                            "id": ids,
                            "subject": this.subject,
                            "type": types
                }
                        }) .then(res=>{
                            this.dialogFormVisible1 = false
                            this.$axios.get("/s/backend/api/message/message_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
            'page':'1',
            'limit':'100',

}
})
  .then(res=>{
         
           this.tableData= res.data.data
        })
        .catch(function(){
           
        })
                        })
            }else{
                this.$message({
                   message:'请填写完整信息',
                  type: 'waring'
                     });
            }


        },

        ck1(){
            this.input1=''
            this.value1 =''
            this.select=''
        },
        ck2(){},
        handleEdit(index, row){

              this.dialogFormVisible1 = true,

                this.content = row.content,
                this.effect_time = row.effect_time,
                this.id = row.id,
                this.subject =row.subject,
                this.type = row.type
        },
     handleEdit1(index, row) {
        
        this.$axios({
        method: "post",
        url: "/s/backend/api/message/message_delete",
        params: {
        accessToken : localStorage.getItem('token')
        },
        data:{
            id:row.id
        }
        }) .then(res=>{
         
            if(res.data.code==0){
                   this.$message({
                   message:'删除成功',
                  type: 'success'
                     });
                   this.$axios.get("/s/backend/api/message/message_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
            'page':'1',
            'limit':'100',

}
})
  .then(res=>{
         
           this.tableData= res.data.data
        })
        .catch(function(){
           
        })
            }
        })
        .catch(function(){
           
        })
      },
    }
}
</script>

<style scoped>

.kk:hover{
    background: #f5f5f5 !important;
    color: #000 !important;
}
/deep/ .el-button+.el-button {
    margin-left:5px
}


/deep/ .el-button--primary{
    width: 100%;
}

/deep/ .el-dialog{
    width: 30%;
}
.l p:hover{
    color: #0034FF;
    border:1px solid #0034FF !important;
         background: #f5f5f5;
}
/deep/ .el-input__inner{
    height:35px;border-radius: 0;
}
   .header{border-bottom: 1px solid #f5f5f5;

min-height: 57px;        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
        .content{
            background: #F5F5F5;padding:10px 40px;
        }
        .content .content_ul{
            display: flex;
            justify-content: space-between;
        }
         .content .content_ul li{
             width: 12%;padding-left: 15px;
         }
         .content_1{
             display: flex;
         }
         .content_1 .left,.right{
             width: 47%;
         }
       /deep/.el-table th > .cell {
      text-align: center;

    }
    /deep/.el-table .cell{
        text-align: center;
    }
</style>